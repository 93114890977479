<template>
  <section class="invoice-preview-wrapper">
    <div v-show="show" id="lottie">
      <div class="loading-logo">
        <img style="margin-left: -4rem;"  alt="Logo" src="/logo-transparent.png" />
      </div>
      <div class="loading">
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>

    <h2
      v-if="changeOrder.id"
      class="notprintable"
      style="
        font-weight: 700 !important;
        font-size: 28px !important;
        line-height: 34px !important;
        color: #636363 !important;
      "
    >
      Change Order {{ changeOrder.change_order_number }}
    </h2>
    <b-col v-if="changeOrder.id" class="notprintable">
      <b-row>
        <p>
          <uil-suitcase class="logo" size="15px" />
          {{ changeOrder.opportunity.account_name }}
        </p>

        <p style="padding-left: 40px">
          <uil-ruler class="logo" size="15px" />
          {{ changeOrder.opportunity.rentable_sqft + " sqft" }}
        </p>
        <p style="padding-left: 40px">
          <b-badge pill :class="badgeClass(changeOrder.status)">
            {{ changeOrder.status }}
          </b-badge>
        </p>
        <b-col align-self="end" style="text-align: end; padding-bottom: 20px">
          <b-button
            style="margin-right: 10px"
            variant="outline-danger"
            @click="showModal"
            v-if="quoteHasChanged || hasComment"
          >
            Discard Changes
          </b-button>
          <b-button
            style="margin-right: 10px"
            variant="outline-secondary"
            @click="exitEditMode"
            v-else
          >
            Exit Edit Mode
          </b-button>
          <b-button
            variant="primary"
            @click="updateQuote"
            :disabled="
              changeOrder.status == 'Archived' ||
              changeOrder.status == 'Approved' ||
              changeOrder.status == 'Sent to Client' ||
              changeOrder.opportunity.marked_as_complete == 1
            "
          >
            Save Quote
          </b-button>
        </b-col>
      </b-row>
    </b-col>
    <b-row v-if="changeOrder.id" class="invoice-preview edit-page">
      <!-- Left Col: Card -->
      <!-- <b-col cols="12" md="4" xl="3" class="invoice-actions">
          <comment-section :comments='comments' :changeOrderId='changeOrderId' />
      </b-col> -->
      <b-col cols="12" md="12" xl="12">
        <b-tabs
          v-model="tabIndex"
          pills
          vertical
          nav-wrapper-class="col-3"
          content-class="col-9"
        >
          <div
            v-if="
              changeOrder.status == 'Archived' ||
              changeOrder.status == 'Approved' ||
              changeOrder.status == 'Sent to Client'
            "
            class="archived-warning"
          >
            <span
              >Quote is Archived, Sent to Client or Approved and can no longer be
              edited.</span
            >
          </div>
          <!-- * start Contract page -->
          <b-tab active>
            <template #title>
              <b-col align-self="start" cols="11">
                <uil-clipboard-alt class="logo" size="15px" />
                Contract Page
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
              />
            </template>
            <b-card class="invoice-preview-card">
              <b-card-body>
                <!-- start general -->
                <p class="bold_text">General</p>
                <div>
                  <!-- start general inputs -->
                  <b-row>
                    <b-col cols="12" lg="6">
                      <span>Request Date</span>
                      <b-input-group class="mb-3">
                        <b-form-input
                          id="request-date"
                          v-model="contractPageForm.request_date"
                          autocomplete="off"
                          placeholder="YYYY-MM-DD"
                          show-decade-nav
                          type="text"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="contractPageForm.request_date"
                            aria-controls="request-date"
                            button-only
                            button-variant="outline-secondary"
                            locale="en-US"
                            right
                            show-decade-nav
                            size="sm"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <span>Requesting Party’s Name</span>
                      <b-input-group class="mb-3">
                        <b-form-input
                          v-model="contractPageForm.requesting_party_name"
                          autocomplete="off"
                          placeholder="John Doe"
                          show-decade-nav
                          type="text"
                        />
                      </b-input-group>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <span>Requesting Party’s Company</span>
                      <b-input-group class="mb-3">
                        <b-form-input
                          v-model="contractPageForm.requesting_party_company"
                          autocomplete="off"
                          placeholder="Acme Inc."
                          show-decade-nav
                          type="text"
                        />
                      </b-input-group>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <span>Owner / Client</span>
                      <b-input-group>
                        <b-form-input
                          v-model="contractPageForm.owner_name"
                          autocomplete="off"
                          placeholder="Name and/or Company"
                          show-decade-nav
                          type="text"
                        />
                      </b-input-group>
                      <small style="color: #b9b9c3">As per CCDC</small>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <span>Issue Date</span>
                      <b-input-group class="mb-3">
                        <b-form-input
                          v-model="contractPageForm.issue_date"
                          autocomplete="off"
                          placeholder="YYYY-MM-DD"
                          show-decade-nav
                          type="text"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="contractPageForm.issue_date"
                            aria-controls="example-input"
                            button-only
                            button-variant="outline-secondary"
                            locale="en-US"
                            right
                            show-decade-nav
                            size="sm"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <span>Expiry Date</span>
                      <b-input-group class="mb-3">
                        <b-form-input
                          v-model="contractPageForm.expiry_date"
                          autocomplete="off"
                          placeholder="YYYY-MM-DD"
                          show-decade-nav
                          type="text"
                          :class="{
                            'text-danger':
                              contractPageForm.issue_date > contractPageForm.expiry_date,
                          }"
                          :min="contractPageForm.issue_date"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="contractPageForm.expiry_date"
                            aria-controls="example-input"
                            button-only
                            button-variant="outline-secondary"
                            locale="en-US"
                            right
                            show-decade-nav
                            size="sm"
                            :min="contractPageForm.issue_date"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                  </b-row>
                  <!-- end general inputs  -->
                  <!--  -->
                </div>
                <!-- end general -->
                <hr />
                <!-- start request details -->
                <p class="bold_text mt-3">Request Details</p>
                <div>
                  <span>Reasons for Change</span>
                  <b-form-select
                    v-model="contractPageForm.type"
                    :options="reasonChangeOptions"
                    class="mb-2"
                  >
                  </b-form-select>
                  <span>Change Order Overview</span>
                  <b-form-textarea
                    v-model="contractPageForm.overview"
                    class="mb-3"
                    id="textarea"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </div>
                <!-- end request details -->
                <hr />
                <!-- start supporting documents -->
                <p class="bold_text mt-3">Supporting Documents</p>
                <div class="mb-3">
                  <span>Supporting Document List</span>
                  <b-form-textarea
                    v-model="contractPageForm.supporting_documents"
                    id="textarea"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                  <small style="color: #b9b9c3"
                    >List all attachments that will be sent with this contract, including
                    the quote.</small
                  >
                </div>
                <!-- end supporting documemts -->
                <hr />
                <!-- start change schedule -->
                <p class="bold_text mt-3">Change in Schedule</p>
                <div class="mb-3">
                  <b-row>
                    <b-col lg="6" cols="12">
                      <span>Current Substantial Completion</span>
                      <b-input-group class="mb-3">
                        <b-form-input
                          v-model="contractPageForm.current_substantial_completion"
                          autocomplete="off"
                          placeholder="YYYY-MM-DD"
                          show-decade-nav
                          type="text"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="contractPageForm.current_substantial_completion"
                            aria-controls="example-input"
                            button-only
                            button-variant="outline-secondary"
                            locale="en-US"
                            right
                            show-decade-nav
                            size="sm"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col lg="6" cols="12">
                      <span>Impact to Schedule in Business Days</span>
                      <b-input-group class="mb-3">
                        <b-form-input
                          v-model="contractPageForm.impact_to_schedule_business_days"
                          autocomplete="off"
                          placeholder="#"
                          show-decade-nav
                          type="number"
                        />
                      </b-input-group>
                    </b-col>
                    <b-col lg="6" cols="12">
                      <span>New Substantial Completion Date</span>
                      <b-input-group class="mb-3">
                        <b-form-input
                          v-model="contractPageForm.new_substantial_completion_date"
                          autocomplete="off"
                          placeholder="YYYY-MM-DD"
                          show-decade-nav
                          type="text"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="contractPageForm.new_substantial_completion_date"
                            aria-controls="example-input"
                            button-only
                            button-variant="outline-secondary"
                            locale="en-US"
                            right
                            show-decade-nav
                            size="sm"
                          />
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </div>
                <!-- end change schedule -->
                <hr />
                <!-- start approving party -->
                <p class="bold_text mt-3">Approving Party</p>
                <div>
                  <b-row>
                    <b-col cols="12" lg="6">
                      <span>Name</span>
                      <b-input-group class="mb-3">
                        <b-form-input
                          v-model="contractPageForm.approving_party_name"
                          autocomplete="off"
                          placeholder="John Doe"
                          show-decade-nav
                          type="text"
                        />
                      </b-input-group>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <span>Title</span>
                      <b-input-group class="mb-3">
                        <b-form-input
                          v-model="contractPageForm.approving_party_title"
                          autocomplete="off"
                          placeholder="Managing Partner"
                          show-decade-nav
                          type="text"
                        />
                      </b-input-group>
                    </b-col>
                    <b-col cols="12" lg="6">
                      <span>Company</span>
                      <b-input-group class="mb-3">
                        <b-form-input
                          v-model="contractPageForm.approving_party_company"
                          autocomplete="off"
                          placeholder="Acme Inc."
                          show-decade-nav
                          type="text"
                        />
                      </b-input-group>
                    </b-col>
                  </b-row>
                </div>
                <!-- end approving party -->
                <hr />
                <!-- start Pjm -->
                <p class="bold_text mt-3">Clearspace Project Manager</p>
                <div>
                  <b-row>
                    <b-col>
                      <span>Name</span>
                      <b-input-group class="mb-3">
                        <b-form-input
                          v-model="contractPageForm.clearspace_project_manager"
                          autocomplete="off"
                          placeholder="John Doe"
                          show-decade-nav
                          type="text"
                        />
                      </b-input-group>
                    </b-col>
                  </b-row>
                </div>
                <!-- end Pjm -->
              </b-card-body>
            </b-card>
          </b-tab>
          <!-- * end contract page -->
          <b-tab>
            <template #title>
              <b-col align-self="start" cols="11">
                <uil-bill class="logo" size="15px" />
                Itemized Quote
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
              />
            </template>
            <b-card class="invoice-preview-card">
              <b-card-body>
                <b-card-title>
                  <b-row>
                    <b-col cols="8">
                      <h2>Itemized Quote</h2>
                    </b-col>
                    <b-col
                      align-self="end"
                      class="d-flex justify-content-end"
                      cols="4"
                      style="text-align: end"
                    >
                      <b-col md="6" align-self="center" align-h="end">
                        <label
                          style="
                            font-weight: 600 !important;
                            font-size: 12px;
                            line-height: 23px;
                            color: #b9b9c3;
                            letter-spacing: 0.6px;
                            text-transform: uppercase;
                          "
                          >Total Price (w/ D&I)</label
                        >
                        <h2
                          style="
                            color: #6e6b7b;
                            font-weight: 500 !important;
                            font-size: 24px;
                            line-height: 24px;
                          "
                        >
                          {{
                           formatter.format(Number(this.totalPrice))
                          }}
                        </h2>
                      </b-col>
                      <b-col md="6" align-self="center" align-h="end">
                        <label
                          style="
                            font-weight: 600 !important;
                            font-size: 12px;
                            line-height: 23px;
                            color: #b9b9c3;
                            letter-spacing: 0.6px;
                            text-transform: uppercase;
                          "
                          >Total COGS</label
                        >
                        <h2
                          style="
                            color: #6e6b7b;
                            font-weight: 500 !important;
                            font-size: 24px;
                            line-height: 24px;
                          "
                        >
                          {{
                            formatter.format(Number(this.totalCost))
                          }}
                        </h2>
                      </b-col>
                    </b-col>
                  </b-row>
                </b-card-title>
                <b-row>
                  <b-col
                    align-self="end"
                    cols="12"
                    style="text-align: end; padding-bottom: 20px"
                  >
                    <b-button
                      v-b-modal.options-modal
                      variant="primary"
                      :disabled="
                        changeOrder.status == 'Archived' ||
                        changeOrder.status == 'Approved' ||
                        changeOrder.status == 'Sent to Client' ||
                        changeOrder.opportunity.marked_as_complete == 1
                      "
                      class="mr-1"
                    >
                      Add Items
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <template v-for="group,index in groups" v-if="group.items && group.items.length>0">
                    <b-col cols="6">
                      <h4 class="mt-2">{{group.label}}</h4>
                    </b-col>
                    <b-col cols="6" class="d-flex justify-content-end">
                      <b-button @click="group.is_collapsed=!group.is_collapsed" class="my-1 mr-1"> {{ group.is_collapsed?'Expand':'Collapse' }} Table</b-button>
                    </b-col>
                    <b-col cols="12">
                      <div class="mr-1">
                        <b-table
                            :sticky-header="true"
                            :no-border-collapse="true"
                            responsive
                            :busy="isBusy"
                            :fields="itemFields"
                            :items="group.items"
                            :style="group.is_collapsed?'height: 27.5vh':'height: 67vh'"
                            style="border-bottom: 1px solid #d3d3d3 !important;"
                        >
                          <template #table-busy>
                            <div class="text-center text-primary my-2">
                              <b-spinner class="align-middle" />
                              <strong> Loading...</strong>
                            </div>
                          </template>

                          <template #cell(name)="data">
                            <div style="max-width: 125px">
                              {{ data.item.name }} <br />
                              <span
                                  v-if="data.item.client_description"
                                  style="
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 18px;
                              letter-spacing: 0px;
                              text-align: left;
                              color: #b9b9c3;
                              white-space: pre-line;
                            "
                              >
                            {{ data.item.client_description }}</span
                              >
                            </div>
                          </template>
                          <template #cell(unit_price)="data">
                            <div
                                :class="[data.item.unit_price < 0 ? 'credit' : '']"
                                style="text-align: end"
                            >
                              {{
                                formatter.format(Number(data.item.unit_price))
                              }}
                            </div>
                          </template>
                          <template #cell(internal_note)="data">
                            <div
                                style="text-align: start; max-width: 150px"
                            >
                              {{
                                data.item.internal_note
                              }}
                            </div>
                          </template>
                          <template #cell(uniformat_code)="data">
                            <div
                                v-if="data.item.uniformat != null"
                                style="width: 150px"
                            >
                              {{
                                searchUniformatCodeName(
                                    data.item.budget_group == "AV/IT"
                                        ? "AVIT"
                                        : data.item.budget_group,
                                    data.item.uniformat
                                )
                              }}
                            </div>
                          </template>
                          <template #cell(unit_cost)="data">
                            <div class="cost-line" style="text-align: end">
                              <uil-arrow-growth
                                  style="color: #ff9f43"
                                  v-if="data.item.is_adjustment == true"
                              />
                              {{
                                formatter.format(Number(Math.abs(data.item.total_cost/changeOrder.opportunity.rentable_sqft)))
                              }}
                            </div>
                          </template>
                          <template #head(unit_cost)>
                       <div class="text-right">
                          <span class="cost-header" style="white-space: nowrap; text-align: end"
                          >COGS / SQFT</span
                          >
                       </div>
                          </template>
                          <template #head(unit_price)>
                       <div class="text-right">
                          <span class="price-header" style="white-space: nowrap; text-align: end"
                          >UNIT PRICE</span
                          >
                       </div>
                          </template>
                          <template #cell(total_price)="data">
                            <div
                                :class="[data.item.total_price < 0 ? 'credit' : '']"
                                style="text-align: end"
                            >
                              {{
                                formatter.format(Number(Math.abs(data.item.total_price)))
                              }}
                            </div>
                          </template>
                          <template #head(total_cost)>
                        <div class="text-right">
                          <span class="cost-header" style="white-space: nowrap; text-align: end;"
                          >Total COGS</span
                          >
                        </div>
                          </template>
                          <template #head(total_price)>
                            <div class="text-right">
                              <span style="white-space: nowrap">TOTAL PRICE (w/ D&I)</span>
                            </div>
                          </template>
                          <template #head(actions)>
                            <span :class="visibleActions()">ACTIONS</span>
                          </template>
                          <template #cell(total_cost)="data">
                            <div class="cost-line" style="text-align: end">
                              {{
                                formatter.format(Number(data.item.total_cost))
                              }}
                            </div>
                          </template>
                          <template #cell(actions)="data">
                            <div class="text-nowrap">
                              <a
                                  v-b-modal.edit-options-modal
                                  @click="editLine(data.item)"
                                  :disabled="
                              changeOrder.status == 'Archived' ||
                              changeOrder.status == 'Approved' ||
                              changeOrder.opportunity.marked_as_complete == 1
                            "
                              >
                                <feather-icon
                                    id="tooltip-eye"
                                    class="mx-1"
                                    icon="Edit3Icon"
                                    size="16"
                                    style="margin-right: 8px !important"
                                />
                                <span class="align-middle" style="margin-right: 15px"
                                >Edit</span
                                >
                              </a>
                              <b-tooltip target="tooltip-eye" title="Edit Option" />

                              <!-- Dropdown -->
                              <b-dropdown no-caret toggle-class="p-0" variant="link">
                                <template #button-content>
                                  <feather-icon
                                      class="align-middle text-body"
                                      icon="MoreVerticalIcon"
                                      size="16"
                                  />
                                </template>
                                <b-dropdown-item
                                    target="_blank"
                                    @click="deleteOption(data.item.id)"
                                    :disabled="
                                changeOrder.status == 'Archived' ||
                                changeOrder.status == 'Approved' ||
                                changeOrder.opportunity.marked_as_complete == 1
                              "
                                >
                                  <feather-icon icon="Trash2Icon" />
                                  <span class="align-middle ml-50">Delete</span>
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </template>

                          <template #cell(budget_group)="data">
                            <b-badge
                                pill
                                variant="primary"
                                :class="data.value.replace(/[^A-Z0-9]+/gi, '')"
                                style="font-weight: 600; font-size: 13px; line-height: 13px"
                            >
                              <div v-if="data.value == 'Construction'">
                                <uil-constructor /> CSTRN
                              </div>
                              <div v-else-if="data.value == 'FF&E'"><uil-chair /> FF&E</div>
                              <div v-else-if="data.value == 'AV/IT'"><uil-wifi /> AVIT</div>
                              <div v-else><uil-user /> SOFT</div>
                            </b-badge>
                          </template>
                          <template #empty="scope">
                            <p class="text-center" style="color: #b9b9c3">
                              No Data to Display
                            </p>
                          </template>
                        </b-table>
                      </div>
                    </b-col>
                  </template>
                </b-row>
<!--                <b-row>
                  <b-col cols="12">
                    <b-table
                      :sticky-header="true"
                      :no-border-collapse="true"
                      responsive
                      :busy="isBusy"
                      :fields="fieldsChangeOrderLines"
                      :items="changeOrderLines"
                      :tbody-tr-class="
                        (changeOrderLines) =>
                          changeOrderLines
                            ? changeOrderLines.budget_group == 'Soft Costs'
                              ? 'is-hidden'
                              : ''
                            : ''
                      "
                      show-empty
                      style="height: 30vh"
                    >
                      <template #table-busy>
                        <div class="text-center text-primary my-2">
                          <b-spinner class="align-middle" />
                          <strong> Loading...</strong>
                        </div>
                      </template>

                      <template #cell(name)="data">
                        <div>
                          {{ data.item.name }} <br />
                          <span
                            v-if="data.item.client_description"
                            style="
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 18px;
                              letter-spacing: 0px;
                              text-align: left;
                              color: #b9b9c3;
                              white-space: pre-line;
                            "
                          >
                            {{ data.item.client_description }}</span
                          >
                        </div>
                      </template>
                      <template #cell(unit_price)="data">
                        <div
                          :class="[data.item.unit_price < 0 ? 'credit' : '']"
                          style="text-align: center"
                        >
                          {{
                            [(data.item.unit_price &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(data.item.unit_price))
                                .toLocaleString(undefined, { minimumFractionDigits: 2 })
                          }}
                        </div>
                      </template>
                      <template #cell(uniformat_code)="data">
                        <div v-if="data.item.uniformat != null">
                          {{
                            searchUniformatCodeName(
                              data.item.budget_group == "AV/IT"
                                ? "AVIT"
                                : data.item.budget_group,
                              data.item.uniformat
                            )
                          }}
                        </div>
                      </template>
                      <template #cell(unit_cost)="data">
                        <div class="cost-line" style="text-align: center">
                          <uil-arrow-growth
                            style="color: #ff9f43"
                            v-if="data.item.is_adjustment == true"
                          />
                          {{
                            [(data.item.unit_cost &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(data.item.total_cost/changeOrder.opportunity.rentable_sqft))
                                .toLocaleString(undefined, { minimumFractionDigits: 2 })
                          }}
                        </div>
                      </template>
                      <template #head(unit_cost)>
                        <span class="cost-header" style="white-space: nowrap"
                          >COGS / SQFT</span
                        >
                      </template>
                      <template #cell(total_price)="data">
                        <div
                          :class="[data.item.total_price < 0 ? 'credit' : '']"
                          style="text-align: center"
                        >
                          {{
                            [(data.item.total_price &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(data.item.total_price))
                                .toLocaleString(undefined, { minimumFractionDigits: 2 })
                          }}
                        </div>
                      </template>
                      <template #head(total_cost)>
                        <span class="cost-header" style="white-space: nowrap"
                          >Total COGS</span
                        >
                      </template>
                      <template #head(total_price)>
                        <span style="white-space: nowrap">TOTAL PRICE (w/ D&I)</span>
                      </template>
                      <template #head(actions)>
                        <span :class="visibleActions()">ACTIONS</span>
                      </template>
                      <template #cell(total_cost)="data">
                        <div class="cost-line" style="text-align: center">
                          {{
                            [(data.item.total_cost &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(data.item.total_cost))
                                .toLocaleString(undefined, { minimumFractionDigits: 2 })
                          }}
                        </div>
                      </template>
                      <template #cell(actions)="data">
                        <div class="text-nowrap">
                          <a
                            v-b-modal.edit-options-modal
                            @click="editLine(data.item)"
                            :disabled="
                              changeOrder.status == 'Archived' ||
                              changeOrder.status == 'Approved' ||
                              changeOrder.opportunity.marked_as_complete == 1
                            "
                          >
                            <feather-icon
                              id="tooltip-eye"
                              class="mx-1"
                              icon="Edit3Icon"
                              size="16"
                              style="margin-right: 8px !important"
                            />
                            <span class="align-middle" style="margin-right: 15px"
                              >Edit</span
                            >
                          </a>
                          <b-tooltip target="tooltip-eye" title="Edit Option" />

                          &lt;!&ndash; Dropdown &ndash;&gt;
                          <b-dropdown no-caret toggle-class="p-0" variant="link">
                            <template #button-content>
                              <feather-icon
                                class="align-middle text-body"
                                icon="MoreVerticalIcon"
                                size="16"
                              />
                            </template>
                            <b-dropdown-item
                              target="_blank"
                              @click="deleteOption(data.item.id)"
                              :disabled="
                                changeOrder.status == 'Archived' ||
                                changeOrder.status == 'Approved' ||
                                changeOrder.opportunity.marked_as_complete == 1
                              "
                            >
                              <feather-icon icon="Trash2Icon" />
                              <span class="align-middle ml-50">Delete</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </template>

                      <template #cell(budget_group)="data">
                        <b-badge
                          pill
                          variant="primary"
                          :class="data.value.replace(/[^A-Z0-9]+/gi, '')"
                          style="font-weight: 600; font-size: 13px; line-height: 13px"
                        >
                          <div v-if="data.value == 'Construction'">
                            <uil-constructor /> CSTRN
                          </div>
                          <div v-else-if="data.value == 'FF&E'"><uil-chair /> FF&E</div>
                          <div v-else-if="data.value == 'AV/IT'"><uil-wifi /> AVIT</div>
                          <div v-else><uil-user /> SOFT</div>
                        </b-badge>
                      </template>
                      <template #empty="scope">
                        <p class="text-center" style="color: #b9b9c3">
                          No Data to Display
                        </p>
                      </template>
                    </b-table>
                    <b-table
                      class="mt-4"
                      :sticky-header="true"
                      :no-border-collapse="true"
                      responsive
                      :busy="isBusy"
                      :fields="fieldsChangeSoft"
                      :items="changeOrderLines"
                      :tbody-tr-class="
                        (changeOrderLines) =>
                          changeOrderLines
                            ? changeOrderLines.budget_group != 'Soft Costs'
                              ? 'is-hidden'
                              : ''
                            : ''
                      "
                      show-empty
                      style="height: 15vh"
                    >
                      <template #table-busy>
                        <div class="text-center text-primary my-2">
                          <b-spinner class="align-middle" />
                          <strong> Loading...</strong>
                        </div>
                      </template>

                      <template #cell(name)="data">
                        <div>
                          {{ data.item.name }} <br />
                          <span
                            v-if="data.item.client_description"
                            style="
                              font-size: 12px;
                              font-style: normal;
                              font-weight: 400;
                              line-height: 18px;
                              letter-spacing: 0px;
                              text-align: left;
                              color: #b9b9c3;
                              white-space: pre-line;
                            "
                          >
                            {{ data.item.client_description }}</span
                          >
                        </div>
                      </template>
                      <template #cell(uniformat_code)="data">
                        <div v-if="data.item.uniformat != null">
                          {{
                            searchUniformatCodeName(
                              data.item.budget_group == "AV/IT"
                                ? "AVIT"
                                : data.item.budget_group,
                              data.item.uniformat
                            )
                          }}
                        </div>
                      </template>
                      <template #cell(unit_price)="data">
                        <div
                          :class="[data.item.unit_price < 0 ? 'credit' : '']"
                          style="text-align: center"
                        >
                          {{
                            [(data.item.unit_price &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(data.item.unit_price))
                                .toLocaleString(undefined, { minimumFractionDigits: 2 })
                          }}
                        </div>
                      </template>
                      <template #cell(unit_cost)="data">
                        <div class="cost-line" style="text-align: center">
                          <uil-arrow-growth
                            style="color: #ff9f43"
                            v-if="data.item.is_adjustment == true"
                          />
                          {{
                            [(data.item.unit_cost &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(data.item.total_cost/changeOrder.opportunity.rentable_sqft))
                                .toLocaleString(undefined, { minimumFractionDigits: 2 })
                          }}
                        </div>
                      </template>
                      <template #head(unit_cost)>
                        <span class="cost-header" style="white-space: nowrap"
                          >COGS / SQFT</span
                        >
                      </template>
                      <template #cell(total_price)="data">
                        <div
                          :class="[data.item.total_price < 0 ? 'credit' : '']"
                          style="text-align: center"
                        >
                          {{
                            [(data.item.total_price &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(data.item.total_price))
                                .toLocaleString(undefined, { minimumFractionDigits: 2 })
                          }}
                        </div>
                      </template>
                      <template #head(total_cost)>
                        <span class="cost-header" style="white-space: nowrap"
                          >Total COGS</span
                        >
                      </template>
                      <template #head(total_price)>
                        <span style="white-space: nowrap">TOTAL PRICE </span>
                      </template>
                      <template #head(actions)>
                        <span :class="visibleActions()">ACTIONS</span>
                      </template>
                      <template #cell(total_cost)="data">
                        <div class="cost-line" style="text-align: center">
                          {{
                            [(data.item.total_cost &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(data.item.total_cost))
                                .toLocaleString(undefined, { minimumFractionDigits: 2 })
                          }}
                        </div>
                      </template>
                      <template #cell(actions)="data">
                        <div class="text-nowrap">
                          <a
                            v-b-modal.edit-options-modal
                            @click="editLine(data.item)"
                            :disabled="
                              changeOrder.status == 'Archived' ||
                              changeOrder.status == 'Approved' ||
                              changeOrder.opportunity.marked_as_complete == 1
                            "
                          >
                            <feather-icon
                              id="tooltip-eye"
                              class="mx-1"
                              icon="Edit3Icon"
                              size="16"
                              style="margin-right: 8px !important"
                            />
                            <span class="align-middle" style="margin-right: 15px"
                              >Edit</span
                            >
                          </a>
                          <b-tooltip target="tooltip-eye" title="Edit Option" />

                          &lt;!&ndash; Dropdown &ndash;&gt;
                          <b-dropdown no-caret toggle-class="p-0" variant="link">
                            <template #button-content>
                              <feather-icon
                                class="align-middle text-body"
                                icon="MoreVerticalIcon"
                                size="16"
                              />
                            </template>
                            <b-dropdown-item
                              target="_blank"
                              @click="deleteOption(data.item.id)"
                              :disabled="
                                changeOrder.status == 'Archived' ||
                                changeOrder.status == 'Approved' ||
                                changeOrder.opportunity.marked_as_complete == 1
                              "
                            >
                              <feather-icon icon="Trash2Icon" />
                              <span class="align-middle ml-50">Delete</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </template>

                      <template #cell(budget_group)="data">
                        <b-badge
                          pill
                          variant="primary"
                          :class="data.value.replace(/[^A-Z0-9]+/gi, '')"
                          style="font-weight: 600; font-size: 13px; line-height: 13px"
                        >
                          <div v-if="data.value == 'Construction'">
                            <uil-constructor /> CSTRN
                          </div>
                          <div v-else-if="data.value == 'FF&E'"><uil-chair /> FF&E</div>
                          <div v-else-if="data.value == 'AV/IT'"><uil-wifi /> AVIT</div>
                          <div v-else><uil-user /> SOFT</div>
                        </b-badge>
                      </template>
                      <template #empty="scope">
                        <p class="text-center" style="color: #b9b9c3">
                          No Data to Display
                        </p>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>-->
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <b-col align-self="start" cols="11">
                <uil-setting class="logo" size="15px" />
                Settings
              </b-col>
              <b-col
                align-self="end"
                class="d-flex align-items-center justify-content-end"
                cols="1"
              />
            </template>
            <settings
              :changeOrder="changeOrder"
              @optionsChanged="settingsChanged"
              @AdminFeeChanged="AdminFeeChanged"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>

    <options-create-modal
      :changeOrderId="changeOrderId"
      :opportunityId="opportunityId"
      :sqft="changeOrder.opportunity.rentable_sqft"
      @updatedLines="childOptions"
      :constructionItems="constructionItems"
    />
    <options-edit-modal
      :itemToEdit="itemToEdit"
      :changeOrderId="changeOrderId"
      :sqft="changeOrder.opportunity.rentable_sqft"
      @updatedLines="childOptions"
      :constructionItems="constructionItems"
    />
  </section>
</template>

<script>
import {
  UilBill,
  UilBox,
  UilCheckCircle,
  UilEditAlt,
  UilExclamationTriangle,
  UilHardHat,
  UilPlus,
  UilRuler,
  UilSuitcase,
  UilTape,
  UilWifi,
  UilSetting,
  UilConstructor,
  UilArrowGrowth,
  UilHistory,
  UilTrashAlt,
  UilUser,
  UilClipboardAlt,
} from "@iconscout/vue-unicons";
import {
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormDatepicker,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTableSimple,
  BTabs,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTooltip,
  BTr,
  VBToggle,
  BBadge,
  BSpinner,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
} from "bootstrap-vue";
import UilChair from "@/assets/CustomIcons/uil-chair";
import JsonExcel from "vue-json-excel";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import OptionsCreateModal from "../ChangeOrderLines/Components/CreateModal.vue";
import OptionsEditModal from "../ChangeOrderLines/Components/EditModal.vue";
import Settings from "./Components/SettingsTab.vue";
import uniformat from "@/assets/objects/uniformat-codes.json";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BFormCheckbox,
    BTableSimple,
    BButton,
    BTable,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BTr,
    BTh,
    BThead,
    BTfoot,
    BTd,
    BTbody,
    Logo,
    UilRuler,
    UilSuitcase,
    UilBill,
    UilTape,
    UilBox,
    UilWifi,
    UilHardHat,
    UilPlus,
    UilCheckCircle,
    UilEditAlt,
    UilExclamationTriangle,
    BFormDatepicker,
    BInputGroupAppend,
    BFormInput,
    BInputGroup,
    BTabs,
    BTab,
    OptionsCreateModal,
    OptionsEditModal,
    BPagination,
    BCardTitle,
    JsonExcel,
    UilSetting,
    BBadge,
    UilChair,
    UilConstructor,
    UilArrowGrowth,
    UilHistory,
    UilTrashAlt,
    UilChair,
    Settings,
    BSpinner,
    UilUser,
    UilClipboardAlt,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
  },
  data() {
    return {
      changeOrderId: this.$route.params.changeOrderId,
      opportunityId: this.$route.params.opportunityId,
      changeOrder: {
        opportunity: {},
      },
      changeOrderLines: [],
      elements: [],
      quote: {
        opportunity: {},
        comments: {},
        construction_cost_per_sqft: 0,
        construction_buffer: 0,
      },
      tabIndex: 0,
      isBusy: true,
      comments: {},
      show: true,
      newComment: "",
      fieldsChangeOrderLines: [
        {
          key: "name",
          label: "ITEM",
          sortable: true,
        },
        {
          key: "internal_note",
          label: "INTERNAL NOTE",
          sortable: false,
        },
        {
          key: "budget_group",
          label: "BUDGET",
          sortable: false,
        },
        {
          key: "uniformat_code",
          label: "tender code",
          sortable: false,
        },
        {
          key: "quantity",
          label: "Quantity",
          sortable: true,
        },
        {
          key: "unit_price",
          label: "UNIT PRICE",
          sortable: false,
        },
        {
          key: "total_price",
          label: "Total Price (w/ D&I)",
          sortable: true,
        },
        {
          key: "unit_cost",
          label: "COGS / Unit",
          sortable: true,
        },
        {
          key: "total_cost",
          label: "Total COGS",
          sortable: true,
        },
        {
          key: "actions",
          label: "ACTIONS",
          tdClass: this.visibleActions,
        },
      ],
      fieldsChangeSoft: [
        {
          key: "name",
          label: "SOFT COST",
          sortable: true,
        },
        {
          key: "internal_note",
          label: "INTERNAL NOTE",
          sortable: false,
        },
        {
          key: "budget_group",
          label: "BUDGET",
          sortable: false,
        },
        {
          key: "uniformat_code",
          label: "tender Code",
          sortable: false,
        },
        {
          key: "quantity",
          label: "Quantity",
          sortable: true,
        },
        {
          key: "unit_price",
          label: "UNIT PRICE",
          sortable: false,
        },
        {
          key: "total_price",
          label: "Total Price",
          sortable: true,
        },
        {
          key: "unit_cost",
          label: "COGS / Unit",
          sortable: true,
        },
        {
          key: "total_cost",
          label: "Total COGS",
          sortable: true,
        },
        {
          key: "actions",
          label: "ACTIONS",
          tdClass: this.visibleActions,
        },
      ],
      options: [],
      itemToEdit: [],
      optionsPerPage: 15,
      optionsCurrentPage: 1,
      stickyHeader: true,
      noCollapse: true,
      constructionItems: [],
      originalIssueDate: "",
      originalConstructionBuffer: 0,
      originalDesignBuffer: 0,
      originalConstructionPmBuffer: 0,
      originalLanguage: "",
      originalAvItToggle: true,
      originalFfeToggle: true,
      assumptionsExclusionsChanged: false,
      buttonDiscard: false,
      childComment: "",
      discardMessage: "",
      reasonChangeOptions: [
        "Client Directed Change",
        "Unforeseen Site Condition",
        "Design/Eng Error + Omission",
      ],
      // contract page vars
      contractPageForm: {
        issue_date: "",
        updated_by: "",
        request_date: "",
        expiry_date: "",
        requesting_party_name: "",
        requesting_party_company: "",
        owner_name: "",
        supporting_documents: "",
        current_substantial_completion: "",
        impact_to_schedule_business_days: "",
        new_substantial_completion_date: "",
        approving_party_name: "Ernesto Galdamez",
        approving_party_title: "Owner",
        approving_party_company: "Clearspace",
        clearspace_project_manager: "Lindsay",
        admin_fee: "15",
        admin_fee_enabled: true,
        // missing inputs
        overview: "",
        type: "Client Directed Change",
      },
      mindate: "",
      // * uniformat codes object
      uniformat_codes: uniformat,
      // grouping variables
      groups: [
        {
          label: "Hard Construction",
          key: "Construction",
          is_collapsed:true
        },
        {
          label: "Furniture",
          key: "FF&E",
          is_collapsed:true
        },
        {
          label: "AV/IT",
          key: "AV/IT",
          is_collapsed:true
        },
        {
          label: "Soft Costs",
          key: "Soft Costs",
          is_collapsed:true
        },
      ],
      itemFields: [
        {
          key: "name",
          label: "Item",
          sortable: true,
        },
        {
          key: "internal_note",
          label: "INTERNAL NOTE",
          sortable: false,
        },
        {
          key: "uniformat_code",
          label: "tender Code",
          sortable: false,
        },
        {
          key: "quantity",
          label: "Quantity",
          sortable: true,
          thStyle: { textAlign: 'right' }, // Align header text to the right
          tdClass: 'text-right', // Apply Bootstrap's text-right class to cells
        },
        {
          key: "unit_price",
          label: "UNIT PRICE",
          sortable: false,
          thStyle: { textAlign: 'right' }, // Align header text to the right
          tdClass: 'text-right', // Apply Bootstrap's text-right class to cells
        },
        {
          key: "total_price",
          label: "Total Price",
          sortable: true,
          thStyle: { textAlign: 'right' }, // Align header text to the right
          tdClass: 'text-right', // Apply Bootstrap's text-right class to cells
        },
        {
          key: "unit_cost",
          label: "COGS / Unit",
          sortable: true,
          thStyle: { textAlign: 'right' }, // Align header text to the right
          tdClass: 'text-right', // Apply Bootstrap's text-right class to cells
        },
        {
          key: "total_cost",
          label: "Total COGS",
          sortable: true,
          thStyle: { textAlign: 'right' }, // Align header text to the right
          tdClass: 'text-right', // Apply Bootstrap's text-right class to cells
        },
        {
          key: "actions",
          label: "ACTIONS",
          tdClass: this.visibleActions,
        },
      ],
      //formater used to give currency value to money amounts
      formatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    };
  },
  computed: {
    totalPrice() {
      var sum = 0;
      this.changeOrderLines.forEach((e) => {
        sum += parseFloat(e.total_price);
      });
      return parseFloat(sum);
    },
    totalCost() {
      var sum = 0;
      this.changeOrderLines.forEach((e) => {
        sum += parseFloat(e.total_cost);
      });
      return parseFloat(sum);
    },
    quoteHasChanged() {
      return this.originalIssueDate != this.changeOrder.issue_date;
    },
    discardModalMessage() {
      if (this.hasComment && this.quoteHasChanged) {
        return "It looks like you have unsaved changes and comments on this page.";
      } else if (this.hasComment) {
        return "It looks like you have unsaved comments on this page.";
      } else {
        return "It looks like you have unsaved changes on this page.";
      }
    },
    montrealAddress() {
      if (this.quote.language == "fr") {
        return "1325-1010 rue de la Gauchetière Ouest";
      } else {
        return "1325-1010 de la Gauchetière West Street";
      }
    },
    hasComment() {
      if (this.childComment != "") {
        return true;
      } else {
        return false;
      }
    },
  },
  beforeMount() {
    this.getQuotes();
  },
  methods: {
    getQuotes() {
      this.$http
        .get(`/opportunities/${this.opportunityId}/change-orders/${this.changeOrderId}`)
        .then((response) => {
          this.changeOrder = response.data.data;
          this.elements = response.data.data.elements;
          //this.constructionItems = response.data.data.construction_elements.filter(x => x.construction_spec !== 'Construction Management');
          this.changeOrderLines = this.changeOrder.change_order_lines;
          this.originalIssueDate = this.changeOrder.issue_date;
          this.show = false;
          this.isBusy = false;
          this.contractPageForm = response.data.data;
          if (this.changeOrder.change_order_number) {
            document.title = `[${this.changeOrder.change_order_number}] Edit Change Order`;
          }
          // this groups the change order lines
          this.groups.forEach((g)=>{
            g.items = this.changeOrderLines.filter((o)=>o.budget_group===g.key)
          })
          this.updateBreadcrumb();
        })
        .catch((error) => {
          // this.showToast('danger', error.response.data.message ?? '')
        });
    },
    updateBreadcrumb() {
      this.$route.meta.breadcrumb[0].text = `${this.changeOrder.opportunity.address}, ${this.changeOrder.opportunity.geography}`;
      if (this.changeOrder.opportunity.marked_as_active) {
        this.$route.meta.breadcrumb[0].to = `/active/${this.opportunityId}`;
      } else {
        this.$route.meta.breadcrumb[0].to = `/opportunities/${this.opportunityId}`;
      }
      this.$route.meta.breadcrumb[0].active = false;
      this.$route.meta.breadcrumb[2].text = `Change Order ${this.changeOrder.change_order_number}`;
      this.$route.meta.breadcrumb[2].to = `/opportunities/${this.opportunityId}`;
      this.$route.meta.breadcrumb[2].active = false;
      this.$root.$emit("rerender-breadcrumb");
    },
    updateQuote() {
      if (
        this.contractPageForm.expiry_date == "" ||
        this.contractPageForm.expiry_date == null
      ) {
        this.showToast("danger", "Enter a valid expiry date", `Invalid expiry date`);
        return false;
      }
      if (
        this.contractPageForm.current_substantial_completion == "" ||
        this.contractPageForm.current_substantial_completion == null
      ) {
        this.showToast(
          "danger",
          "Date is required and cannot be empty",
          `Current Substantial Completion `
        );
        return false;
      }
      let cf = this.contractPageForm;
      this.buttonDiscard = true;
      this.$http
        .put(`/opportunities/${this.opportunityId}/change-orders/${this.changeOrderId}`, {
          language: this.changeOrder.language,
          ...cf,
        })
        .then((response) => {
          this.showToast(
            "success",
            `Change Order ${cf.change_order_number} has been successfully saved.`,
            "Change Order saved successfully"
          );
          this.$router.push({
            path: `/opportunities/${this.opportunityId}/change-order/${this.changeOrderId}`,
          });
        })
        .catch((error) => {
          this.showToast("danger", "Error updating Change Order Quote", "Notification");
        });
    },
    initials(text) {
      const initial = text
        .match(/(\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();

      return initial;
    },
    editLine(item) {
      this.itemToEdit = item;
    },
    deleteOption(id) {
      this.$http
        .delete(
          `/opportunities/${this.opportunityId}/change-orders/${this.changeOrderId}/change-orders-lines/${id}`
        )
        .then((response) => {
          this.showToast("success", "Item Deleted Successfully", "Notification");
          this.changeOrderLines = response.data.change_order_lines;
          // this groups the change order lines
          this.groups.forEach((g)=>{
            g.items = this.changeOrderLines.filter((o)=>o.budget_group===g.key)
          })
        })
        .catch((error) => {
          this.showToast("danger", "Error Deleting Item", "Notification");
        });
    },

    childOptions(variable) {
      this.changeOrderLines = variable.change_order_lines;
      // this groups the change order lines
      this.groups.forEach((g)=>{
        g.items = this.changeOrderLines.filter((o)=>o.budget_group===g.key)
      })
    },
    settingsChanged(settings) {
      this.changeOrder.issue_date = settings.issue_date;
      this.changeOrder.language = settings.language;
    },
    AdminFeeChanged(AdminFee) {
      this.changeOrder.admin_fee = AdminFee.adminFee;
      this.changeOrder.admin_fee_enabled = AdminFee.adminFeeEnabled;
    },
    commentChanged(comment) {
      this.childComment = comment;
    },
    showModal() {
      this.buttonDiscard = true;
      this.$bvModal
        .msgBoxConfirm(this.discardModalMessage, {
          title: "You Have Unsaved Changes",
          size: "sm",
          okVariant: "outline-danger",
          okTitle: "Discard Changes",
          cancelTitle: "Return to Edit",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$router.push({
              path: `/opportunities/${this.opportunityId}/change-order/${this.changeOrderId}`,
            });
          } else {
            this.buttonDiscard = false;
            return false;
          }
        });
    },
    exitEditMode() {
      this.$router.push({
        path: `/opportunities/${this.opportunityId}/change-order/${this.changeOrderId}`,
      });
    },
    visibleActions() {
      return this.changeOrder.status == "Archived" ||
        this.changeOrder.status == "Approved" ||
        this.changeOrder.status == "Sent to Client" ||
        this.changeOrder.opportunity.marked_as_complete == 1
        ? "d-none"
        : "";
    },
    badgeClass(status) {
      if (status == "Draft") {
        return "draft";
      } else if (status == "Approved") {
        return "approved";
      } else if (status == "Archived") {
        return "archived";
      } else if (status == "Quote In Progress") {
        return "quote-in-progress";
      } else if (status == "Sent to Client") {
        return "sent-to-client";
      }
    },
    searchUniformatCodeName(subgroup, code) {
      let codeName = "";
      this.uniformat_codes[subgroup].map((u) => {
        if (u.code == code) {
          codeName = u.code_name;
        }
      });
      return codeName;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.buttonDiscard && (this.quoteHasChanged || this.hasComment)) {
      this.$bvModal
        .msgBoxConfirm(this.discardModalMessage, {
          title: "You Have Unsaved Changes",
          size: "sm",
          okVariant: "outline-danger",
          okTitle: "Discard Changes",
          cancelTitle: "Return to Edit",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            next();
          } else {
            next(false);
          }
        });
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }

  .breadcrumbs-top {
    display: none;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  [dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
  [dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
    background-color: rgba(92, 177, 231, 0.12) !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  th {
    text-align: left !important;
  }

  #lottie {
    display: none;
  }

  .caption-table {
    /* Heading/H4 */

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    padding-left: 40px;
    color: #5e5873;
  }

  .row-label {
    /* Body/Paragraph Semi Bold */

    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    /* identical to box height, or 150% */

    align-items: center;

    /* Typography/Body */

    color: #6e6b7b;
  }

  .border-group {
    border-bottom: 2px solid #d8d6de;
  }

  .border-child {
    border-bottom: 2px solid white;
  }

  .child-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }

  .border-footer {
    border-top: 2px solid  rgba(59, 171, 255, 1) ;
  }

  .invoice-numbercs {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
  }

  .header-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .header-label-grey {
    /* Table/Table Header */

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */

    text-align: right;
    letter-spacing: 1px;
    text-transform: uppercase;

    /* Typography/Muted & Placeholder */

    color: #b9b9c3;
  }

  .header-subtotal {
    font-style: normal;
    font-weight: 600;
    font-size: 11.9px;
    line-height: 12px;
    /* identical to box height, or 100% */

    text-align: right;
    letter-spacing: 0.14px;

    /* Typography/Body */

    color: #6e6b7b;
  }

  .project-sqft {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    /* Typography/Heading & Display */

    color: #5e5873;
  }

  .project-sqft-total {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    text-align: end;

    /* Theme Colour / Primary */

    color:  rgba(59, 171, 255, 1) ;
  }

  .width-100p {
    width: 100%;
  }
}

th {
  text-align: left !important;
}

.comment-label {
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}

.caption-table {
  /* Heading/H4 */

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  padding-left: 40px;
  color: #5e5873;
}

.row-label {
  /* Body/Paragraph Semi Bold */

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  /* identical to box height, or 150% */

  align-items: center;

  /* Typography/Body */

  color: #6e6b7b;
}

.border-group {
  border-bottom: 2px solid #d8d6de;
}

.border-child {
  border-bottom: 2px solid white;
}

.child-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.border-footer {
  border-top: 2px solid  rgba(59, 171, 255, 1) ;
}

.invoice-numbercs {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
}

.header-label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.header-label-grey {
  /* Table/Table Header */

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  /* identical to box height */

  text-align: right;
  letter-spacing: 1px;
  text-transform: uppercase;

  /* Typography/Muted & Placeholder */

  color: #b9b9c3;
}

.header-subtotal {
  font-style: normal;
  font-weight: 600;
  font-size: 11.9px;
  line-height: 12px;
  /* identical to box height, or 100% */

  text-align: right;
  letter-spacing: 0.14px;

  /* Typography/Body */

  color: #6e6b7b;
}

.project-sqft {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  /* Typography/Heading & Display */

  color: #5e5873;
}

.project-sqft-total {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-align: end;

  /* Theme Colour / Primary */

  color:  rgba(59, 171, 255, 1) ;
}

.width-100p {
  width: 100%;
}

.child-line {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #b9b9c3;
}

.cost-line {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #b9b9c3;
}

.cost-header {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #b9b9c3;
}

.child-description {
  font-size: 12px;
  color: #b9b9c3;
  line-height: 18px;
}

.turnkey-subtotal-primary {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #6e6b7b;
}

.turnkey-subtotal-secondary {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #b9b9c3;
}

.credit {
  color:  rgba(59, 171, 255, 1) ;
}

.disclaimer {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #b9b9c3;
}

.labelpersqft {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;

  color: #b9b9c3;
}

th {
  text-align: center;
}

.alert-warning {
  width: 100%;
  background: linear-gradient(0deg, rgba(255, 159, 67, 0.12), rgba(255, 159, 67, 0.12)),
    #ffffff;
  border-radius: 4px;
  margin-bottom: 2rem;
  height: 39px;
  display: flex;
  align-items: center;
}

.alert-warning > span {
  color: #ff9f43;
  font-weight: 700;
  font-size: 14px;
  padding-left: 2%;
}
.hidden-flex-line > td {
  font-size: 12px;
}

.remove-border > td {
  border: none !important;
}
.border-section > td {
  border-bottom: 1px solid #ebe9f1;
}

.input-group > .reset-append-border > .input-group-text {
  border-left: 1px solid #d8d6de !important;
  border-top-left-radius: 0.357rem !important;
  border-bottom-left-radius: 0.357rem !important;
}
.input-group:focus-within > .reset-append-border > .input-group-text {
  border-left: 1px solid  rgba(59, 171, 255, 1)  !important;
  border-top-left-radius: 0.357rem !important;
  border-bottom-left-radius: 0.357rem !important;
}

// @media screen and (min-width: 360px) and (max-width: 1300px) {
//     .edit-page [role=tablist] {
//       width: min-content;
//     }
// }
// @media screen and (max-width: 1440px){
//     .edit-page [role=tablist] {
//       width: 260px;
//     }
// }
// @media screen and (min-width: 1441px){
//     .edit-page [role=tablist] {
//       width: 350px;
//     }
// }

.FFE {
  background-color: rgba(32, 201, 151, 0.12);
  color: #20c997;
}

.Construction {
  background-color: rgba(255, 159, 67, 0.12);
  color: #ff9f43;
}

.AVIT {
  background-color: rgba(13, 110, 253, 0.12);
  color: #7367f0;
}

.SoftCosts {
  background-color: rgba(214, 51, 132, 0.12);
  color: #d63384;
}

.b-table-sticky-header {
  max-height: unset;
}
.is-hidden {
  display: none;
}

.archived-warning {
  width: 100%;
  background: #d4f4f7;
  border-radius: 4px;
  margin-bottom: 1rem;
  height: 39px;
  display: flex;
  align-items: center;
}

.archived-warning > span {
  color: #03d8ed;
  font-weight: 700;
  font-size: 14px;
  padding-left: 2%;
}

</style>
